h1,
h2 {
  font-weight: normal;
  padding-left: 40px;
  padding-right: 40px;
  margin-block-start: 5px;
  margin-block-end: 5px;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 40px;
}

@media (max-width: 767px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
  .splash {
    height: 300px;
  }
}
