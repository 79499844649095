.nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.nav a:link {
  text-decoration: none;
}

.nav a:hover {
  color: white;
}

@media (max-width: 767px) {
  .nav {
    font-size: 16px;
  }
  .logo {
    height: 25px;
    margin-left: 15px;
  }
}
