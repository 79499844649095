.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

@font-face {
  font-family: "ChunkFive";
  src: local("ChunkFive-Regular"),
    url(./fonts/ChunkFive-Regular.otf) format("opentype");
}

@font-face {
  font-family: "EksellDisplayWeb-Medium";
  src: local("EksellDisplayWeb-Medium"),
    url(./fonts/EksellDisplayWeb-Medium.otf) format("opentype");
}

body {
  background-color: #2b2b2b;
  color: white;
  background-size: auto;
}

button {
  background-color: #4caf50;
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

button:hover {
  background-color: #e7e7e7;
  color: black;
}

.delete:hover,
.cancel:hover {
  background-color: #f67f76;
  color: white;
}

.trans-details {
  list-style: none;
  width: auto;
}

.submit {
  background-color: #008cba;
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.submit:hover {
  background-color: #e7e7e7;
  color: black;
}

h1,
h2 {
  font-family: ChunkFive;
}

a {
  color: rgb(98, 215, 117);
}

a:hover {
  color: white;
}

ul {
  padding-inline-start: 0px;
}
